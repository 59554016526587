<template>
  <div class="invoiceEdit">
    <!-- 抬头类型个人 -->
    <div>
      <van-cell-group>
        <van-field
          readonly
          clickable
          is-link
          input-align="right"
          label="抬头类型"
          :value="enterpriseType"
          placeholder="选择类型"
          @click="entpPicker = true"
        />
        <van-popup v-model="entpPicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="enterpriseTypeList"
            @cancel="entpPicker = false"
            @confirm="confirmsEntp"
          />
        </van-popup>
        <div>
          <!-- 个人发票抬头 -->
          <van-cell title="发票抬头" :value="invoiceLook" v-if="lookType" />
          <!-- 企业发票抬头-->
          <van-field
            v-model="invoiceLook"
            label="发票抬头"
            right-icon="edit"
            placeholder="请输入发票抬头"
            input-align="right"
            v-else
          />
        </div>

        <div>
          <!--  个人发票类型-->
          <van-cell title="发票类型" :value="invoiceType" v-if="lookType" />
          <!-- 企业发票类型 -->
          <van-field
            readonly
            clickable
            is-link
            input-align="right"
            label="发票类型"
            :value="invoiceType"
            placeholder="选择发票类型"
            @click="invoicePicker = true"
            v-else
          />
          <van-popup v-model="invoicePicker" round position="bottom">
            <van-picker
              show-toolbar
              :columns="invoiceList"
              @cancel="invoicePicker = false"
              @confirm="confirmInvo"
              @change="changIndex"
            />
          </van-popup>
        </div>

        <!-- 联系姓名 -->
        <van-field
          v-model="name"
          label="联系姓名"
          right-icon="edit"
          placeholder="请输入联系姓名"
          input-align="right"
          clearable
          :rules="[{ pattern, message: '请输入正确内容' }]"
        />
        <!-- 联系电话 -->
        <van-field
          v-model="phone"
          label="联系电话"
          right-icon="edit"
          placeholder="请输入联系电话"
          input-align="right"
          clearable
          :rules="[{ pattern, message: '请输入正确内容' }]"
        />
        <!-- 联系地址 -->
        <van-field
          v-model="address"
          label="联系地址"
          right-icon="edit"
          placeholder="请输入联系地址"
          input-align="right"
        />

        <div v-if="!lookType">
          <!-- 企业税号 -->
          <van-field
            v-model="entpNumber"
            label="企业税号"
            right-icon="edit"
            placeholder="请输入发票抬头"
            input-align="right"
          />
          <!-- 开户银行 -->
          <van-field
            v-model="bank"
            label="开户银行"
            right-icon="edit"
            placeholder="请输入开户银行"
            input-align="right"
          />
          <!-- 开户账号 -->
          <van-field
            v-model="bankNumber"
            label="开户账号"
            right-icon="edit"
            placeholder="请输入开户账号"
            input-align="right"
          />
        </div>

        <!-- 身份证号 -->
        <van-field
          v-model="IDNumber"
          label="身份证号"
          right-icon="edit"
          placeholder="请输入身份证号"
          input-align="right"
          clearable
          v-if="lookType"
        />
        <!-- <van-cell center title="默认发票信息">
          <template #right-icon>
            <van-switch v-model="checkedInfo" size="24" />
          </template>
        </van-cell> -->
      </van-cell-group>
      <!-- 保存按钮 -->
      <van-button
        type="default"
        hairline
        size="large"
        class="saveInvoice"
        @click="submit"
        >保存</van-button
      >
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { invoiceInfo } from "@api/user";
import globalFunction from "@utils/globalFunction";
import {
  Picker,
  Popup,
  Field,
  Cell,
  CellGroup,
  Switch,
  Button,
  Checkbox,
  CheckboxGroup,
  NavBar,
  Toast,
} from "vant";

Vue.use(NavBar);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Button);
Vue.use(Switch);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Field);
Vue.use(Popup);
Vue.use(Picker);
export default {
  data() {
    return {
      id: "",
      enterpriseTypeList: ["个人", "企业"],
      // 抬头类型
      enterpriseType: "个人",
      enterpriseTypeN: 1,
      entpPicker: false,
      //   发票抬头
      invoiceLook: "个人",
      //   发票类型
      invoiceList: ["普通发票", "专用发票"],
      invoiceType: "普通发票",
      invoiceTypeIndex: 0, //发票类型  索引  
      invoiceTypeN: 1,
      invoicePicker: false,
      // 企业税号
      entpNumber: "",
      // 开户银行
      bank: "",
      // 开户账号
      bankNumber: "",
      // 联系姓名
      name: "",
      // 联系地址
      address: "",
      // 联系电话
      phone: "",
      pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      // 身份证号
      IDNumber: "",
      checkedInfo: true,
      checked: false,
    };
  },
  computed: {
    lookType() {
      return this.enterpriseType == "" || this.enterpriseType == "个人";
    },
  },
  created() {
    this.id = this.$route.params.id;
    let address = JSON.parse(decodeURIComponent(this.$route.params.address));
    this.address = address.receiver_address;
    this.phone = address.receiver_mobile;
    this.name = address.receiver_name;
  },
  methods: {
    // onClickLeft() {
    //   this.$router.back(-1);
    // },
    //   选择抬头类型
    confirmsEntp(enterpriseType) {
      this.enterpriseType = enterpriseType;
      if (this.enterpriseType == "个人") {
        this.invoiceLook = enterpriseType;
        this.invoiceType = "普通发票";
        this.enterpriseTypeN = 1;
        this.invoiceTypeN = 1;
      } else {
        this.invoiceLook = "";
        this.invoiceType = "";
        this.enterpriseTypeN = 2;
        this.invoiceTypeN = "";
      }

      this.entpPicker = false;
    },
    //   选择发票类型
    confirmInvo(invoiceType) {
      this.invoiceType = invoiceType;
      this.invoicePicker = false;
      if (this.invoiceTypeIndex == 0) {
        this.invoiceTypeN = 1;
      } else {
        this.invoiceTypeN = 2;
      }
      // console.log(this.invoiceTypeN);
    },
    changIndex(picker, value, index) {
      this.invoiceTypeIndex = index;
    },

    // 发送请求
    sendAjax() {
      // console.log(this.enterpriseTypeN);
      var reg_user = /^[\u4e00-\u9fa5]{2,4}$/; //2-4个中文字符正则
      var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      var reg_id = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

      if (!this.name) return this.$dialog.error("请填写联系姓名");
      if (!reg_user.test(this.name))  return this.$dialog.error("姓名只能为2-4中文！");
      if (!this.phone) return this.$dialog.error("请填写联系电话");
      if (!reg_tel.test(this.phone)) return this.$dialog.error("请正确填写您的手机号码！");

      if (!this.address) return this.$dialog.error("请填写联系地址");

      if (this.enterpriseTypeN == 1) {
        if (!this.IDNumber) return this.$dialog.error("请填写身份证号");
        if (!reg_id.test(this.IDNumber)) return this.$dialog.error("请正确填写身份证号");
      } else {
        if (!this.invoiceLook) return this.$dialog.error("请填写发票抬头");
        if (!this.invoiceTypeN) return this.$dialog.error("请选择发票类型");
        if (!this.entpNumber) return this.$dialog.error("请填写企业税号");
        if (!this.bank) return this.$dialog.error("请填写开户银行");
        if (!this.bankNumber) return this.$dialog.error("请填写银行账号");
      }
      const params = {
        catid: this.enterpriseTypeN,
        level_id: this.id,
        type: this.invoiceTypeN,
        name: this.invoiceLook,
        number: this.entpNumber,
        id_card: this.IDNumber,
        bank_name: this.bank,
        bank_number: this.bankNumber,
        receiver_name: this.name,
        receiver_mobile: this.phone,
        receiver_address: this.address,
      };
      invoiceInfo(params).then((res) => {
        if (res.status != 200) return this.$dialog.error(res.msg);
        this.$dialog.success(res.msg);
        setTimeout(() => {
          this.$router.push("/user/invoice");
        }, 1500);
      });
    },
    // 提交  防止多次提交
    submit:globalFunction.debounce(function(){
      this.sendAjax()
    },2000),
  },
};
</script>

<style lang="scss" scoped>
.invoiceEdit {
  .saveInvoice {
    margin-top: 10px;
    color: #94191d;
    font-weight: 500;
  }
  // 选择阅读
  .select {
    background-color: #f7f8fa;
  }
}
</style>>
